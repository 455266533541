// =========== Layout

.l-large {
	max-width: 60rem;
	margin: 0 auto;
}

.l-medium {
	max-width: 45rem;
	margin: 0 auto;
}

.l-small {
	max-width: 32rem;
	margin: 0 auto;
}
