// ============ Content

.content--wrapper {
	position: relative;
	height: 100%;
	overflow-x: auto;
}

div.content {
	padding: 0 @spacing;

	@media @breakpoint-two {
		padding: 0 @spacing*2;
	}
}
