// ============ Consent

.consent {
	font-size: 1rem;
	color: @gray-80;

	strong {
		display: block;
		margin-bottom: 0.75rem;
	}

	ul {
		padding: 0 0 0 1rem;
		margin: 0;
	}

	li {
	}

	small {
		display: block;
		margin-bottom: @spacing*2;
	}
}
