@import "_icons-variables";
@import "_fortytwo-icons";

// Iconen in ParnasSys.

//ACTION ICONS --> Clickable action button

.icon-include {
	text-decoration: none;
	border-radius: 4px;
	font-size: @font-size + 6px;
	line-height: 32px;
	width: 32px;
	height: 32px;
	display: block;
	overflow: hidden;
	text-align: center;
}

.border-icon-include {
	width: 24px;
	height: 24px;
	line-height: 26px;
	box-shadow: none;
	background-color: @white;
}

a.iconClick,
.iconClick {
	.icon-include;

	&:hover {
		cursor: pointer;
	}

	&.icon-primary {
		background-color: @primary1;
		color: @white;
		box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);

		&:hover {
			cursor: pointer;
			background-color: @primary2;
			box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
		}
	}

	&.icon-confirm {
		background-color: @tertiary31;

		&:hover {
			background-color: @color-tint-60;
			box-shadow: 0px 1px 1px @color-tint-70;
		}
	}

	&.icon-secondary {
		.border-icon-include;
		color: @primary1;
		border: 2px solid @primary1;

		&:hover {
			color: @primary2;
			background-color: @white;
			border: 2px solid @primary2;
			box-shadow: none;
		}
	}

	&.icon-destructive {
		.border-icon-include;
		color: @font3;
		border: 2px solid @font3;

		&:hover {
			color: @font2;
			background-color: @white;
			width: 22px;
			height: 22px;
			line-height: 24px;
			border: 3px solid @font2;
			box-shadow: none;
		}
	}

	&.disabled,
	&.icon-disabled {
		.border-icon-include;
		border: 2px solid @greyscale3;
		color: @greyscale3;

		&:hover {
			background-color: @white;
			border: 2px solid @greyscale3;
			box-shadow: none;
			color: @greyscale3;
			cursor: default;
		}
	}

	&.icon15x15 {
		line-height: 19px;
		width: 15px;
		height: 15px;
	}
}

// icon properties for use inside div elements.

div.fieldsetbox > a.iconClick {
	margin-bottom: -30px;
	margin-left: 8px;
	float: right;

	&.nextTo {
		display: inline-block;
		margin: 0 2px;
		float: none;
		vertical-align: middle;
	}
}

div.fieldsetbox > a.iconEditImage {
	margin-left: 150px;
}

// DESCRIPTIVE ICONS --> not clickable, used to describe content

.iconDescribe.icon-primary {
	.icon-include;
	display: inline;
	color: @color-brand;
}
.iconDescribe.icon-secondary {
	.icon-include;
	display: inline;
	color: @gray;
}
.iconDescribe.icon-dark {
	.icon-include;
	display: inline;
	color: @font1;
}
.iconDescribe.icon-green {
	.icon-include;
	color: @positive1;
}

.iconDescribe.icon-yellow {
	.icon-include;
	color: @tertiary41;
}

.iconDescribe.icon-blue {
	.icon-include;
	color: @tertiary21;
}

// icon properties for use in table rows.

td .iconClick,
td .iconDescribe {
	&.nextTo {
		display: inline-block;
		margin-left: 4px;
		vertical-align: middle;
	}
	&.float-right {
		margin-right: 2px;
		float: right;
	}
}

// icon properties for use in table headers

th > a.iconClick,
th .filter-icon,
th > span > a.iconClick,
.laySearchbar span.iconClick {
	margin-right: 2px;
	float: right;

	&.nextTo {
		display: inline-block;
		margin: 0 0 0 4px;
	}
	&.notificationIconImage {
		float: none;
		display: inline-block;
	}
}

// Filter icon is a legacy element used on the 'MijnParnasSys page'

.filter-icon {
	.icon-include;
	background-color: @white;
	background-size: contain;
	color: @greyscale4;
	text-align: center;
	&:hover {
		background-color: @greyscale6;
	}

	i {
		line-height: 23px;
	}

	&.is-active {
		background-color: @color-brand;
		color: @white;
		&:hover {
			box-shadow: 0px 1px 1px @color-brand-70;
			background-color: @color-brand-60;
		}
	}
}

// desc

.inpDescription {
	font-size: @font-size + 2px;
	float: right;
	margin-top: 2px;
	height: 16px;
}

td > .inputdescription {
	float: right;
	display: block;
}

.descTableCell {
	display: inline-block;
	margin-top: 5px;
}

.inlist-icon {
	margin: 0 5px;
	display: inline-block;
	overflow: hidden;
	background-size: contain;
	background-repeat: no-repeat;
	vertical-align: middle;
	color: @color-brand;
	font-size: @font-size + 4px;

	&.important {
		width: 20px;
		height: 20px;
		padding-right: 10px;
	}
}

// non-font icons in FortyTwo style

.parro-icon {
	.icon-include;
	color: white;
	content: url("@{images}/ps-icon-bnd-parro-alt.svg");
}

// searchbar icons

.ui-dropdownchecklist-dropcontainer {
	height: 297px;
	overflow-x: hidden;
}

.ui-datepicker-trigger {
	.icon-calendar-date-white;
	margin-left: 4px;
	vertical-align: middle;
	width: 32px;
	height: 32px;
	border-radius: @border-radius-large;
	background-color: @primary1;
	box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);

	&:hover {
		cursor: pointer;
		background-color: @primary2;
	}
}

.tblInput {
	.ui-datepicker-trigger {
		margin-top: -2px;
	}
}

.ui-icon-search {
	vertical-align: -5px;
	&:hover {
		cursor: pointer;
	}
}
.overleden:after {
	content: " \2020";
}

// datagrid print options

.btnGroeperen,
.btnKolommen,
.btnExport,
.btnAfdrukken {
	font-family: @icomoon-font-family;
	font-weight: normal;
}

.btnGroeperen:before {
	content: @icon-bullet-list;
}
.btnKolommen:before {
	content: @icon-data-table;
}
.btnExport:before {
	content: @icon-spreadsheets;
}
.btnAfdrukken:before {
	content: @icon-print;
}

// datgrid icons

.notificationImage {
	font-family: @icomoon-font-family;
}

.tblDatagrid .ongelezen td.notificationImage:before {
	content: @icon-mail-unread;
	width: 24px;
}

.tblDatagrid .gelezen td.notificationImage:before {
	content: @icon-mail-read;
	width: 24px;
}

// FortyTwo icons

.icon-calendar-date {
	background-image: url("@{images}/fortytwo-icons/icon-calendar-date.svg");
	background-repeat: no-repeat;
	background-position: center center;
}

.icon-calendar-date-white {
	background-image: url("@{images}/fortytwo-icons/icon-calendar-date-white.svg");
	background-repeat: no-repeat;
	background-position: center center;
}

.icon-common-add {
	background-image: url("@{images}/fortytwo-icons/icon-common-add.svg");
	background-repeat: no-repeat;
	background-position: center center;
}

.icon-common-remove {
	background-image: url("@{images}/fortytwo-icons/icon-common-remove.svg");
	background-repeat: no-repeat;
	background-position: center center;
}

.icon-nav-arrowdown {
	background-image: url("@{images}/fortytwo-icons/icon-nav-arrowdown.svg");
	background-repeat: no-repeat;
	background-position: center center;
}

.icon-nav-arrowup {
	background-image: url("@{images}/fortytwo-icons/icon-nav-arrowup.svg");
	background-repeat: no-repeat;
	background-position: center center;
}

.icon-right-panel-open {
	background-image: url("@{images}/fortytwo-icons/icon-right-panel-open.svg");
	background-repeat: no-repeat;
	background-position: center center;
}

.icon-right-panel-open-primary1 {
	background-image: url("@{images}/fortytwo-icons/icon-right-panel-open-primary1.svg");
	background-repeat: no-repeat;
	background-position: center center;
}
