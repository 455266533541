// =========== Forms

input[type="search"] {
	-webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="tel"],
input[type="url"],
textarea,
select {
	-webkit-appearance: none;
	border: 2px solid darken(@gray, 5%);
	color: lighten(@black, 35%);
	display: block;
	font-family: @font-family;
	font-size: inherit;
	line-height: 1.5rem;
	margin: 0;
	padding: 0.5rem;
	width: 100% !important;
	vertical-align: baseline;
	.mixin-border-radius(@border-radius);
	.mixin-box-shadow(inset 0px 1px 3px fade(@black, 10%));
	.mixin-transition(border 0.1s);

	&:focus {
		border-color: darken(@gray, 18%);
		outline: 0;
		color: @black;
	}
}

textarea {
	overflow: auto;
	resize: vertical;
	vertical-align: top;
	display: block;
}

.form-wrapper {
	//.include-panel;
	padding-top: @vertical-spacing * 2;

	div.form-buttons {
		text-align: right;
		padding-left: 1rem;
		padding-right: 1rem;
		@media only screen and (min-width: @breakpoint-one) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
}
form {
}

fieldset {
}

legend {
	border: 0;
	display: block;
	margin-bottom: 0.25rem;
	font-weight: bold;
	line-height: 1.5rem;
	font-size: 1.25rem;
}
