// ============ authenticator

.authenticator--choice {
	width: 100%;
	height: 100vh;
	position: relative;
	color: @white;
	overflow: auto;

	&-header {
		background-color: @primary1;

		.login--logo {
			height: 60px;
			width: 165px;
			margin: 0 auto;
		}
	}
}

.authenticator {
	font-family: @font-family;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	overflow: auto;
	background: url(../images/inlog-background.svg) no-repeat;
	background-position: top right -17px;
	background-size: cover;

	@media @breakpoint-media-two {
		background-size: 100% 100%;
	}
}

.authenticator--panel {
	position: relative;
	padding: 1.5rem;
	background: @white;
	border-radius: 4px;
	width: 100%;
	box-shadow: 0px 8px 22px -6px rgba(13, 13, 13, 0.12),
	0px 14px 64px -4px rgba(13, 13, 13, 0.12);

	@media @breakpoint-media-one {
		min-width: 23rem;
		width: auto;

		> :not(.authenticator--panel-admin) {
			max-width: 23rem;
		}
	}

	a + hr {
		margin-top: 1.5rem;
	}

	hr {
		border: none;
		border-top: 1px solid #b8c4c3;
		margin: 0 0 0 -1.5rem;
		width: ~"calc(100% + 3rem)";
	}
}

.authenticator--panel-admin {
	position: relative;

	hr {
		margin: 1.5rem 0 0.75rem -1.5rem;
	}
}

.authenticator--header {
	display: none;
	text-align: right;
	padding: @spacing*3 0 0.25rem;
	text-transform: uppercase;
	font-size: 0.733rem; //@font-size-10;
	margin-right: @spacing;

	@media @breakpoint-media-one {
		display: block;
	}

	a {
		font-weight: 400;
		color: @color-neutral-60;
		margin-left: @spacing;
	}
}

.authenticator--table {
	overflow-y: auto;
	max-height: 23rem;

	a {
		cursor: pointer;
		color: @primary1;
		font-weight: 700;

		&:hover {
			color: @primary2;
		}

		+ a {
			margin-left: 0.5rem;
		}
	}
}

.authenticator--linkpanel {
	font-family: @font-family;
	margin-bottom: 1.5rem;
	padding: 0;

	li {
		border-radius: 0px;
		list-style: none;
		border-bottom: 1px solid @border1;
		width: 99%;
		padding-left: 0;
		cursor: pointer;

		&:first-child {
			border-radius: 0px;
			border-top: 1px solid @border1;
		}

		a {
			font-size: 1rem;
			display: block;
			padding: 1.25rem 0.375rem;
			text-decoration: none;
			color: @primary1;
			font-weight: 700;

			&:hover {
				background-color: @greyscale7;
			}

			i {
				font-style: normal;
				display: inline-block;
				float: right;
				font-size: 1.25rem;
			}

			.organisatieNaam {
				font-size: 0.875rem;
				color: @font2;
			}
		}
	}
}