// ============ Base
// TODO: more structure, variables en less

*[hidden] {
	display: none !important;
}

BODY {
	background-color: @gray-10;
	font-family: @font-family;
	margin: 0;
	color: @font1;
	height: 100%;
	overflow-y: hidden;
	width: 100%;
	min-width: 875px;
	font-size: @font-size;
	line-height: 20px;
}

/* ID voor de div waarin alle data wordt getoond*/
#main {
	position: relative;
	z-index: 1;
	margin-top: 10px;
}

html {
	overflow: auto;
	background-color: @greyscale8;
}

h1 {
	color: @font1;
	font-size: @font-size + 2px;
	font-weight: @medium;
}

h2 {
	color: @font1;
	font-size: @font-size;
	font-weight: @medium;
}

h3 {
	color: @font1;
	font-size: @font-size;
	font-weight: @medium;
}

p.whitelabel {
	font-size: @font-size;
	font-weight: bold;
	color: @white;
	text-transform: lowercase;
}

a {
	text-decoration: none;
	color: @color-brand;

	&:active,
	&:visited,
	&:link,
	&:hover {
		text-decoration: none;
		color: @color-brand;
	}
}

li {
	padding-left: 10px;
	margin-left: 2px;
}

li.ui-menu-item {
	padding-left: 0px;
}

ul li {
	list-style: disc outside;
}

ol li {
	list-style: decimal outside;
}

li li {
	list-style: circle outside;
	padding-left: 5px;
	margin-left: 0;
}

table {
	border: 0;
	font-size: @font-size;
}

pre {
	font-family: @font-family;
	font-size: @font-size;
}

textarea {
	font-family: @font-family;
	font-size: @font-size;
	border: 1px solid @gray;
	padding: 2px;
}

fieldset {
	border: 0 none;
}

INPUT {
	height: 30px;
	font-size: @font-size;
	font-family: @font-family;
	padding: 0 8px;
	border-radius: @border-radius-large;
}

INPUT.btn {
	height: 100%;
}

INPUT.btn_disabled {
	height: 100%;
}

input[type="file"] {
	height: auto;
	width: 265px;
}

input.inpText[type="file"] {
	width: 280px;
	height: 24px;
	border: 0;
	left: 10px;
	position: absolute;
	top: 8px;
	opacity: 0;
	z-index: 1;
	margin-left: 120px;
}

input.fileName {
	position: absolute;
	border: none;
	border-radius: @border-radius-large;
	padding: 0px 8px;
	margin-left: 132px;
	width: -moz-available; /* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
	background: white;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

input[type="radio"] {
	vertical-align: bottom;
	+ label {
		// label recht achter radio button uitlijnen
		vertical-align: text-bottom;
		margin-top: 2px;
	}
}

input[readonly="readonly"] {
	background: @gray-40;
}

img {
	border: 0;
}

SELECT {
	height: 32px;
	font-family: @font-family;
	font-size: @font-size;
	border: 1px solid @greyscale3;
	background-color: @white;
	border-radius: @border-radius-large;
	// afwijkende padding omdat select options vanuit browser al 2px padding krijgen
	padding: 6px;
}

select:focus,
select:focus-visible,
select:focus-within {
	border: 1px solid @black !important;
	outline: none !important;
}

.input-disabled {
	border: 1px solid @greyscale3;
	background-color: @greyscale7;
	color: @font4;
}

input:disabled {
	.input-disabled();
}

.all-input-disabled {
	.inpSelect:disabled, .inpText:disabled {
		.input-disabled();
	}
}
