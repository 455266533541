// =========== Filter

.filter {
	& + table {
		margin-top: -1.5rem;
	}

	form {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
	}

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	input[type="email"],
	input[type="tel"],
	input[type="url"],
	select {
		font-family: @font-family;
		width: 100%;
		height: 2.5rem;
		font-size: 1rem;
		font-weight: normal;
		background-color: @white;
		border-radius: 4px;
		border: 1px solid @greyscale3;
		box-sizing: border-box;
		box-shadow: none;
		line-height: 1;

		&.error {
			border: 1px solid @negative1;
			color: @black!important;
		}

		&:focus,
		&:active {
			border: 1px solid @primary1;
		}

		&:disabled {
			background-color: @gray-30;
		}
	}

	input[type="submit"],
	input[type="button"],
	a.button,
	button {
		.include-suite-button;
		width: 32%;
		margin-left: auto;
	}

	label {
		cursor: pointer;
		margin-right: 0.5rem;
	}

	form {
		.span2 {
			width: 32% !important;
		}

		> input.span2 {
			margin-right: 2%;
			margin-bottom: 1.5rem;
		}

		> input.span2:nth-child(4) {
			margin-right: 0;
		}
	}
}
