// ============ Stacktrace

pre.stacktrace {
	max-width: 90%;
	color: @white;
	background: fade(@white, 10%);
	padding: 1.5rem;
	overflow-x: scroll;
	text-align: left;
	margin: 0 auto @spacing;
	min-height: 10rem;
	font-size: 0.8rem;

	@media @breakpoint-two {
		overflow: auto;
		margin: 0 auto @spacing;
	}
}

.stacktrace--body {
	.l-small;
	font-size: 1rem;

	h1 {
		padding: 0;
		margin: 0;
		color: @font1;
		font-weight: normal;
		font-size: 1.5rem;
		margin-bottom: 1.25rem;
	}

	label {
		display: block;
		text-align: center;
		font-weight: 700;
	}

	p > a {
		color: @primary1;
		font-weight: 700;

		&:hover {
			color: @primary2;
		}
	}
}
