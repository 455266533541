// FortyTwo styling voor buttons in ParnasSys
/* Ga bij het maken van nieuwe knoppen uit van een <a> element met class = "btn-(primary/secondary/confirm)" */

.button-include {
	text-decoration: none;
	border: none;
	color: @white;
	border-radius: 4px;
	font-size: @font-size;
	font-weight: @medium;
	font-family: @font-family;
	white-space: nowrap;
	cursor: pointer;
	margin-right: 8px;
	padding: 8px 12px;
	display: flex;
	align-items: center;
	width: fit-content;

	a {
		text-decoration: none;
		color: @white;
	}
}

.laySearchbar fieldset a.btn-primary {
	height: 24px;
	line-height: 24px;
}

INPUT.btn,
button.btn,
.widget-button a,
a.btn-primary,
a.btn:link {
	.button-include;
	background-color: @primary1;
	box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);

	&:hover {
		color: @font5;
		background-color: @primary2;
		box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
	}
}

// separate styling for affirmative action buttons
button.btn-affirmative,
.widget-button.btn-affirmative a,
div.btn-affirmative a,
a.btn-affirmative,
a.btn-affirmative:link {
	.button-include;
	background-color: @tertiary31;
	box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);

	&:hover,
	&:active {
		color: @white;
		background-color: @tertiary32;
		box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
	}
}

// separate styling for secondary action buttons
button.btn-secondary,
.widget-button.btn-secondary a,
a.btn-secondary,
a.btn-secondary:link {
	.button-include;
	color: @primary1;
	background-color: @white;
	border: 2px solid @primary1;
	box-shadow: none;
	padding: 6px 10px;

	&:hover {
		color: @primary2;
		background-color: @white;
		border: 3px solid @primary2;
		box-shadow: none;
		padding: 5px 9px;
	}
}

// separate styling for secondary action buttons
button.btn-secondary-affirmative,
.widget-button.btn-secondary-affirmative a,
a.btn-secondary-affirmative,
a.btn-secondary-affirmative:link {
	.button-include;
	color: @tertiary31;
	background-color: @white;
	border: 2px solid @tertiary31;
	box-shadow: none;
	padding: 6px 10px;

	&:hover {
		color: @tertiary32;
		background-color: @white;
		border: 3px solid @tertiary32;
		box-shadow: none;
		padding: 5px 9px;
	}
}

// separate styling for disabled buttons
button.btn-disabled,
input.btn-disabled,
.widget-button.btn-disabled a,
a.btn-disabled,
a.btn-disabled:link {
	.button-include;
	cursor: default;
	color: @greyscale3;
	background-color: @white;
	border: 2px solid @greyscale3;
	box-shadow: none;
	padding: 6px 10px;
	&:hover {
		cursor: default;
		color: @greyscale3;
		background-color: @white;
		border: 2px solid @greyscale3;
		box-shadow: none;
		padding: 6px 10px;
	}
}

// separate styling voor destructive buttons
button.btn-destructive,
.widget-button.btn-destructive a,
a.btn-destructive {
	.button-include;
	color: @font3;
	background-color: @white;
	border: 2px solid @font3;
	box-shadow: none;
	padding: 6px 10px;
	&:hover {
		color: @font2;
		border: 3px solid @font2;
		padding: 5px 9px;
	}
}

// separate styling voor ghost-destructive buttons
button.btn-ghost-destructive,
.widget-button.btn-ghost-destructive a,
a.btn-ghost-destructive {
	.button-include;
	color: @font3;
	background-color: @white;
	border: 0;
	box-shadow: none;
	&:hover {
		color: @font2;
	}
}

// separate styling voor ghost-primary buttons
button.btn-ghost-primary,
.widget-button.btn-ghost-primary a,
a.btn-ghost-primary {
	.button-include;
	color: @primary1;
	background-color: @white;
	border: 0;
	box-shadow: none;
	&:hover {
		color: @primary1;
	}
}

// AbstractMultiActionBottomRowButton maakt een menu met JQuery-UI en we willen de stijl van onze bottomrow, vandaar deze hacky override.
.muActions.ui-widget-content {
	border: none !important;
	background-color: transparent !important;
	position: absolute !important;

	li {
		padding: 0px 0px 2px 0px !important;
		height: 32px;

		a {
			padding: 6px 10px !important;
			margin: 4px;

			&.ui-state-active {
				color: @white !important;
				background-color: @primary2 !important;
				background-image: none !important;
				font-weight: @medium;
				padding: 5px 9px !important;
			}
		}
	}
}

// Button-wrapper voor de file upload button
.button-wrap {
	position: relative;
	width: 400px;
	height: 32px;
	&.afbeelding-wrap {
		display: flex;
		align-items: center;
		padding-left: 8px;
	}
	.btn-wrap {
		.button-include;
		padding: 6px 12px; //andere padding nodig omdat het component een label is ipv button
		position: absolute;
		background-color: @primary1;
		box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);

		&:hover {
			color: @font5;
			background-color: @primary2;
			box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
		}
		&.afbeelding-upload {
			margin-left: -10px;
		}
	}
}

.file-picker-label {
	width: fit-content;
	padding-right: 8px;
	white-space: nowrap;
}

#infoButton {
	background: @tertiary11;
	color: @white;
	position: fixed;
	bottom: 0px;
	height: 16px;
	width: 16px;
	margin-left: 10px;
	padding-left: 3px;
	padding-top: 2px;
	border-top-left-radius: @border-radius-large;
	border-top-right-radius: @border-radius-large;
	z-index: 2;
	cursor: pointer;

	&:hover {
		background: @tertiary12;
	}
}
#infoButton:before {
	font-family: @icomoon-font-family;
	content: @icon-info;
}

.include-suite-button {
	font-family: @font-family;
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 0.9rem 1rem;
	font-size: 1rem;
	font-weight: 600;
	border-radius: 4px;
	cursor: pointer;
	border: 0;
	vertical-align: baseline;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	color: @white;
	background-color: @primary1;
	box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
	box-sizing: border-box;

	@media @breakpoint-media-two {
		padding: 0.75rem 1rem;
	}

	&:hover,
	&:active,
	&:focus {
		color: @white;
		background-color: @primary2;
	}

	&.is-disabled {
		cursor: default !important;
		color: @font4;
		background-color: @greyscale7;
		box-shadow: none;

		&:hover,
		&:active,
		&:focus {
			color: @font4;
			background-color: @greyscale7;
		}
	}
}

a.authenticator--button {
	.include-suite-button;
}

a.authenticator--button-secondary {
	.include-suite-button;
	background-color: @tertiary31;

	&:hover,
	&:active,
	&:focus {
		background-color: @tertiary32;
	}
}

a.authenticator--button-cancel {
	.include-suite-button;
	background-color: @white;
	color: @font3;
	border: 2px solid @font3;
	box-shadow: none;

	&:hover,
	&:active,
	&:focus {
		color: @font2;
		border: 2px solid @font2;
		background-color: @white;
	}
}

a.authenticator--button-outlined {
	.include-suite-button;
	background-color: @white;
	color: @primary1;
	border: 2px solid @primary1;
	box-shadow: none;

	&:hover,
	&:active,
	&:focus {
		color: @primary2;
		border: 2px solid @primary2;
		background-color: @white;
	}
}
