/*
* This is the where we place utility classes such as .text-center, .font-small, .visually-hidden, bg-dark. 
* Utility classes are used to apply a single specific rule to an element, so we should guarantee that the 
* style explained by the class name is actually applied. This requires us to write high specificity classes 
* and for this reason THIS IS THE ONLY PLACE where the use of !important should be allowed.
*/

.tekst-blauw {
	color: @grade-blue;
}

.tekst-groen {
	color: @grade-green;
}

.tekst-geel {
	color: @grade-yellow;
}

.tekst-oranje {
	color: @grade-orange;
}

.tekst-rood {
	color: @grade-red;
}

.tekst-grijs-disabled {
	color: @greyscale1;
}

.tekst-magenta {
	color: @color-brand !important;
}

.invisible {
	visibility: hidden;
}

.hide {
	// niet +!important, want wordt o.a. bij de ui-dialog-content (zoals ParnasSysInfoButtonPanel) overschreven
	display: none;
}

.is-hidden, .hidden {
	display: none !important;
}

.display_flex {
	display: flex;
}

.display_contents {
	display: contents;
}

.display_block {
	display: block;
}

.display_inline-block {
	display: inline-block !important;
}

.display_grid {
	display: grid;
}

.grid-gap_8 {
	grid-gap: 8px;
}

.font_size_14 {
	font-size: 14px;
}

.font_size_15 {
	font-size: 15px;
}

.font_size_16 {
	font-size: 16px;
}

.font_size_20 {
	font-size: 20px;
}

.font-weight-regular {
	font-weight: 400 !important;
}

.bold {
	font-weight: bold !important;
}

.medium {
	font-weight: @medium !important;
}

.italic {
	font-style: italic !important;
}

.font-regular {
	font-weight: 400;
}

.font-medium {
	font-weight: 500;
}

.font-bold {
	font-weight: 700;
}

.font-size-plus-2 {
	font-size: @font-size + 2px !important;
}

.initial-line-height {
	line-height: initial;
}

.smaller_line-height {
	line-height: 1.2em;
}

.inline {
	white-space: nowrap;
	display: inline !important;
}

.noWrap {
	white-space: nowrap;
}

.breakWrap {
	word-wrap: break-word;
}

.noWidth {
	width: auto !important;
}

.clear {
	clear: both !important;
	display: block !important;
}

.border-top-dungrijs {
	border-top: @dungrijs;
}

.border-1-solid-white {
	border: 1px solid white !important;
}

.border-radius-2-left {
	border-radius: 2px 0 0 2px !important;
}

.border-radius-2-right {
	border-radius: 0 2px 2px 0 !important;
}

.border-radius-4-left {
	border-radius: 4px 0 0 4px !important;
}

.border-radius-4-right {
	border-radius: 0 4px 4px 0 !important;
}

.nofieldsetborder {
	border: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.noBoxShadow {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.topBoxShadow {
	-webkit-box-shadow: inset 0px 1px 0px 0px @greyscale4 !important;
	-moz-box-shadow: inset 0px 1px 0px 0px @greyscale4 !important;
	box-shadow: inset 0px 1px 0px 0px @greyscale4 !important;
}

.topBoxShadowOverwritable {
	-webkit-box-shadow: inset 0px 1px 0px 0px @greyscale4;
	-moz-box-shadow: inset 0px 1px 0px 0px @greyscale4;
	box-shadow: inset 0px 1px 0px 0px @greyscale4;
}

.topBottomBoxShadow {
	-webkit-box-shadow: inset 0px 1px 0px 0px @greyscale4, inset 0px -1px 0px 0px @greyscale4 !important;
	-moz-box-shadow: inset 0px 1px 0px 0px @greyscale4, inset 0px -1px 0px 0px @greyscale4 !important;
	box-shadow: inset 0px 1px 0px 0px @greyscale4, inset 0px -1px 0px 0px @greyscale4 !important;
}

.rightBoxShadow {
	-webkit-box-shadow: 2px 0px 0px 0px @greyscale4 !important;
	-moz-box-shadow: 2px 0px 0px 0px @greyscale4 !important;
	box-shadow: 2px 0px 0px 0px @greyscale4 !important;
}

.innerRightBoxShadow {
	-webkit-box-shadow: inset -1px 0px 0px 0px @greyscale4 !important;
	-moz-box-shadow: inset -1px 0px 0px 0px @greyscale4 !important;
	box-shadow: inset -1px 0px 0px 0px @greyscale4 !important;
}

.bottomBoxShadow {
	-webkit-box-shadow: inset 0px -1px 0px 0px @greyscale4 !important;
	-moz-box-shadow: inset 0px -1px 0px 0px @greyscale4 !important;
	box-shadow: inset 0px -1px 0px 0px @greyscale4 !important;
}

.rightBottomBoxShadow {
	-webkit-box-shadow: inset 0px -1px 0px 0px @greyscale4, 2px 0px 0px 0px @greyscale4 !important;
	-moz-box-shadow: inset 0px -1px 0px 0px @greyscale4, 2px 0px 0px 0px @greyscale4 !important;
	box-shadow: inset 0px -1px 0px 0px @greyscale4, 2px 0px 0px 0px @greyscale4 !important;
}

.rightTopBoxShadow {
	-webkit-box-shadow: inset 0px 1px 0px 0px @greyscale4, 2px 0px 0px 0px @greyscale4 !important;
	-moz-box-shadow: inset 0px 1px 0px 0px @greyscale4, 2px 0px 0px 0px @greyscale4 !important;
	box-shadow: inset 0px 1px 0px 0px @greyscale4, 2px 0px 0px 0px @greyscale4 !important;
}

.innerRightTopBoxShadow {
	-webkit-box-shadow: inset 0px 1px 0px 0px @greyscale4, inset -1px 0px 0px 0px @greyscale4 !important;
	-moz-box-shadow: inset 0px 1px 0px 0px @greyscale4, inset -1px 0px 0px 0px @greyscale4 !important;
	box-shadow: inset 0px 1px 0px 0px @greyscale4, inset -1px 0px 0px 0px @greyscale4 !important;
}

.topBottomBoxShadow {
	-webkit-box-shadow: inset 0px -1px 0px 0px @greyscale4, inset 0px 1px 0px 0px @greyscale4 !important;
	-moz-box-shadow: inset 0px -1px 0px 0px @greyscale4, inset 0px 1px 0px 0px @greyscale4 !important;
	box-shadow: inset 0px -1px 0px 0px @greyscale4, inset 0px 1px 0px 0px @greyscale4 !important;
}

.topRightBottomBoxShadow {
	-webkit-box-shadow: inset 0px -1px 0px 0px @greyscale4, 2px 0px 0px 0px @greyscale4,
	inset 0px 1px 0px 0px @greyscale4 !important;
	-moz-box-shadow: inset 0px -1px 0px 0px @greyscale4, 2px 0px 0px 0px @greyscale4,
	inset 0px 1px 0px 0px @greyscale4 !important;
	box-shadow: inset 0px -1px 0px 0px @greyscale4, 2px 0px 0px 0px @greyscale4,
	inset 0px 1px 0px 0px @greyscale4 !important;
}

.left-float,
.left {
	float: left;
}

.right {
	float: right !important;
}

.float_none {
	float: none !important;
}

.align-center {
	text-align: center;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right !important;
}

.highlight-green-30 {
	background: @tertiary34 !important;
}

.highlight-green {
	background: @tertiary31 !important;
}

.highlight-gray-7 {
	background: @greyscale7 !important;
}

.left_-300 {
	left: -300px !important;
}

.position_absolute {
	position: absolute;
}

.position_static {
	position: static !important;
}

.position_relative {
	position: relative;
}

.position_fixed {
	position: fixed;
}

.bottom_-5 {
	bottom: -5px;
}

.no-padding {
	padding: 0 !important;
}

.padding_5 {
	padding: 5px;
}

.padding_6 {
	padding: 6px;
}

.padding_8 {
	padding: 8px;
}

.padding_16 {
	padding: 16px !important;
}

.padding_left_0 {
	padding-left: 0px;
}

.padding_left_1 {
	padding-left: 1px !important;
}

.padding_left_8 {
	padding-left: 8px;
}

.padding_left_10 {
	padding-left: 10px;
}

.padding_left_12 {
	padding-left: 12px;
}

.padding_left_15 {
	padding-left: 15px;
}

.padding_left_16 {
	padding-left: 16px !important;
}

.padding_left_30 {
	padding-left: 30px;
}

.padding_left_50 {
	padding-left: 50px;
}

.padding_right_1 {
	padding-right: 1px !important;
}

.padding_right_5 {
	padding-right: 5px;
}

.padding_right_8 {
	padding-right: 8px;
}

.padding_right_10 {
	padding-right: 10px;
}

.padding_right_12 {
	padding-right: 12px !important;
}

.padding_right_15 {
	padding-right: 15px;
}

.padding_right_16 {
	padding-right: 16px !important;
}

.padding_right_20 {
	padding-right: 20px;
}

.padding_4_10 {
	padding: 4px 10px;
}

.padding_5_10 {
	padding: 5px 10px;
}

.padding_0_20 {
	padding: 0 20px;
}

.padding_10_20 {
	padding: 10px 20px !important;
}

.padding_bottom_0 {
	padding-bottom: 0px !important;
}

.padding_bottom_5 {
	padding-bottom: 5px;
}

.padding_bottom_8 {
	padding-bottom: 8px !important;
}

.padding_bottom_10 {
	padding-bottom: 10px;
}

.padding_bottom_15 {
	padding-bottom: 15px;
}

.padding_bottom_16 {
	padding-bottom: 16px !important;
}

.padding_top_0 {
	padding-top: 0px !important;
}

.padding_top_3 {
	padding-top: 3px;
}

.padding_top_8 {
	padding-top: 8px;
}

.padding_top_15 {
	padding-top: 15px;
}

.padding_top_20 {
	padding-top: 20px !important;
}

.padding_top_37 {
	padding-top: 37px;
}

.padding_10_40_15_40 {
	padding: 10px 40px 15px 40px !important;
}

.padding_8_16 {
	padding: 8px 16px !important;
}

.padding_4_0 {
	padding: 4px 0px;
}

/* util */
.unit_1 {
	width: 1px !important;
}

.unit_8 {
	width: 8px !important;
}

.unit_10 {
	width: 10px !important;
}

.unit_14 {
	width: 14px !important;
}

.unit_17 {
	width: 17px !important;
}

.unit_20 {
	width: 20px !important;
}

.unit_25 {
	width: 25px !important;
}

.unit_30 {
	width: 30px !important;
}

.unit_32 {
	width: 32px !important;
}

.unit_35 {
	width: 35px !important;
}

.unit_40 {
	width: 40px !important;
}

.unit_45 {
	width: 45px !important;
}

.unit_50 {
	width: 50px !important;
}

.unit_60 {
	width: 60px !important;
}

.unit_70 {
	width: 70px !important;
}

.unit_75 {
	width: 75px !important;
}

.unit_80 {
	width: 80px !important;
}

.unit_85 {
	width: 85px !important;
}

.unit_90 {
	width: 90px !important;
}

.unit_100 {
	width: 100px !important;
}

.unit_110 {
	width: 110px !important;
}

.unit_120 {
	width: 120px !important;
}

.unit_130 {
	width: 130px !important;
}

.unit_135 {
	width: 135px !important;
}

.unit_140 {
	width: 140px !important;
}

.unit_150 {
	width: 150px !important;
}

.unit_160 {
	width: 160px !important;
}

.unit_180 {
	width: 180px !important;
}

.unit_190 {
	width: 190px !important;
}

.unit_197 {
	width: 197px !important;
}

.unit_200 {
	width: 200px !important;
}

.unit_220 {
	width: 220px !important;
}

.unit_227 {
	width: 227px !important;
}

.unit_240 {
	width: 240px !important;
}

.unit_250 {
	width: 250px !important;
}

.unit_260 {
	width: 260px !important;
}

.unit_270 {
	width: 270px !important;
}

.unit_280 {
	width: 280px !important;
}

.unit_290 {
	width: 290px !important;
}

.unit_300 {
	width: 300px !important;
}

.unit_310 {
	width: 310px !important;
}

.unit_320 {
	width: 320px !important;
}

.unit_324 {
	width: 324px !important;
}

.unit_340 {
	width: 340px !important;
}

.unit_350 {
	width: 350px !important;
}

.unit_360 {
	width: 360px !important;
}

.unit_380 {
	width: 380px !important;
}

.unit_400 {
	width: 400px !important;
}

.unit_420 {
	width: 420px !important;
}

.unit_435 {
	width: 435px !important;
}

.unit_440 {
	width: 440px !important;
}

.unit_460 {
	width: 460px !important;
}

.unit_480 {
	width: 480px !important;
}

.unit_500 {
	width: 500px !important;
}

.unit_520 {
	width: 520px !important;
}

.unit_540 {
	width: 540px !important;
}

.unit_560 {
	width: 560px !important;
}

.unit_600 {
	width: 600px !important;
}

.unit_630 {
	width: 630px !important;
}

.unit_640 {
	width: 640px !important;
}

.unit_650 {
	width: 650px !important;
}

.unit_700 {
	width: 700px !important;
}

.unit_750 {
	width: 750px !important;
}

.unit_780 {
	width: 780px !important;
}

.unit_800 {
	width: 800px !important;
}

.unit_810 {
	width: 810px !important;
}

.unit_830 {
	width: 830px !important;
}

.unit_900 {
	width: 900px !important;
}

.unit_910 {
	width: 910px !important;
}

.unit_925 {
	width: 925px !important;
}

.unit_940 {
	width: 940px !important;
}

.unit_950 {
	width: 950px !important;
}

.unit_960 {
	width: 960px !important;
}

.unit_1050 {
	width: 1050px !important;
}

.unit_1100 {
	width: 1100px !important;
}

.unit_1250 {
	width: 1250px !important;
}

.unit_1450 {
	width: 1450px !important;
}

.unitHeight_24 {
	height: 24px !important;
}

.unitHeight_26 {
	height: 26px !important;
}

.unitHeight_28 {
	height: 28px !important;
}

.unitHeight_30 {
	height: 30px !important;
}

.unitHeight_32 {
	height: 32px !important;
}

.unitHeight_35 {
	height: 35px !important;
}

.unitHeight_38 {
	height: 38px !important;
}

.unitHeight_54 {
	height: 54px !important;
}

.unitHeight_100 {
	height: 100px !important;
}

.unitHeight_140 {
	height: 140px !important;
}

.unitHeight_200 {
	height: 200px !important;
}

.unitHeight_300 {
	height: 300px !important;
}

.unitHeight_400 {
	height: 400px !important;
}

.dynamic_unit_0 {
	width: 0% !important;
}

.dynamic_unit_175 {
	width: 1.75% !important;
}

.dynamic_unit_2 {
	width: 2% !important;
}

.dynamic_unit_5 {
	width: 5% !important;
}

.dynamic_unit_10 {
	width: 10% !important;
}

.dynamic_unit_14 {
	width: 14% !important;
}

.dynamic_unit_15 {
	width: 15% !important;
}

.dynamic_unit_20 {
	width: 20% !important;
}

.dynamic_unit_25 {
	width: 25% !important;
}

.dynamic_unit_30 {
	width: 30% !important;
}

.dynamic_unit_33 {
	width: 33% !important;
}

.dynamic_unit_35 {
	width: 35% !important;
}

.dynamic_unit_39 {
	width: 39% !important;
}

.dynamic_unit_40 {
	width: 40% !important;
}

.dynamic_unit_45 {
	width: 45% !important;
}

.dynamic_unit_48 {
	width: 48% !important;
}

.dynamic_unit_49 {
	width: 49% !important;
}

.dynamic_unit_50 {
	width: 50% !important;
}

.dynamic_unit_55 {
	width: 55% !important;
}

.dynamic_unit_60 {
	width: 60% !important;
}

.dynamic_unit_65 {
	width: 65% !important;
}

.dynamic_unit_70 {
	width: 70% !important;
}

.dynamic_unit_75 {
	width: 75% !important;
}

.dynamic_unit_80 {
	width: 80% !important;
}

.dynamic_unit_85 {
	width: 85% !important;
}

.dynamic_unit_90 {
	width: 90% !important;
}

.dynamic_unit_95 {
	width: 95% !important;
}

.dynamic_unit_99 {
	width: 99% !important;
}

.dynamic_unit_100 {
	width: 100% !important;
}

.max_width_240 {
	max-width: 240px !important;
}

.max_width_350 {
	max-width: 350px !important;
}

.max_width_400 {
	max-width: 400px !important;
}

.max_width_600 {
	max-width: 600px !important;
}

.width_fit_content {
	width: fit-content;
}

.no-margin {
	margin: 0 !important;
}

.margin_-3 {
	margin: -3px;
}

.margin-top-0 {
	margin-top: 0 !important;
}

.margin-top_-104 {
	margin-top: -104px !important;
}

.margin-top-4 {
	margin-top: 4px;
}

.margin-top-7 {
	margin-top: 7px;
}

.margin-top-8 {
	margin-top: 8px !important;
}

.margin-top-10 {
	margin-top: 10px;
}

.margin-top-15 {
	margin-top: 15px;
}

.margin-top-16 {
	margin-top: 16px !important;
}

.margin-top-20 {
	margin-top: 20px;
}

.margin-top-30 {
	margin-top: 30px;
}

.margin-block-end-0 {
	margin-block-end: 0;
}

.margin-bottom-0 {
	margin-bottom: 0px !important;
}

.margin-bottom-6 {
	margin-bottom: 6px !important;
}

.margin-bottom-8 {
	margin-bottom: 8px !important;
}

.margin-bottom-10 {
	margin-bottom: 10px !important;
}

.margin-bottom-12 {
	margin-bottom: 12px !important;
}

.margin-bottom-16 {
	margin-bottom: 16px !important;
}

.margin-left-4 {
	margin-left: 4px;
}

.margin-left-5 {
	margin-left: 5px;
}

.margin-left-6 {
	margin-left: 6px;
}

.margin-left-8 {
	margin-left: 8px;
}

.margin-left-10 {
	margin-left: 10px;
}

.margin-left-16 {
	margin-left: 16px;
}

.margin-left-28 {
	margin-left: 28px;
}

.margin-left-dynamic-38 {
	margin-left: 38% !important;
}

.margin-right-2 {
	margin-right: 2px;
}

.margin-right-4 {
	margin-right: 4px;
}

.margin-right-5 {
	margin-right: 5px;
}

.margin-right-6 {
	margin-right: 6px !important;
}

.margin-right-8 {
	margin-right: 8px;
}

.margin-right-10 {
	margin-right: 10px;
}

.margin-right-16 {
	margin-right: 16px;
}

.margin-right-35 {
	margin-right: 35px;
}

.vertical_align_middle {
	vertical-align: middle;
}

.vertical_align_text_top {
	vertical-align: text-top;
}

.vertical_align_top {
	vertical-align: top !important;
}

.flex_align_middle {
	display: flex;
	align-items: center;
}

.flex_align_center {
	justify-content: center;
}

.flex_column {
	flex-direction: column;
}

.fill_available {
	width: -webkit-fill-available;
	width: -moz-available; // Firefox support
}

.border_bottom_none {
	border-bottom: none !important;
}

.cursor_pointer {
	cursor: pointer;
}

.cursor_default {
	cursor: default !important;
}

.secret_kopieren {
	cursor: pointer;
	color: @primary1;
}

.panel-disabled {
	.tekst-grijs-disabled;

	a {
		.tekst-grijs-disabled;
		pointer-events: none;
		text-decoration: none;
	}

	input:disabled {
		pointer-events: none;
		background-color: @primary2;
	}
}
