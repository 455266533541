// ============ Login
form.login {
	font-family: @font-family;
	padding: 0;
	margin: 0 auto;

	fieldset {
		// TODO: remove this with reset in base
		margin: 0;
		padding: 0;
	}

	.input-icon-wrapper {
		position: relative;

		.input-icon {
			position: absolute;
			top: 54%;
			left: 0.5rem;
			transform: translateY(-50%);
			color: @font3;
			font-size: 1.25rem;
			font-style: normal;
		}

		input {
			padding-left: 2rem;
		}
	}

	.password-input-wrapper {
		display: flex;
		align-items: center;
		border-radius: 4px;
		border: 1px solid @greyscale3;
		margin-bottom: 1.5rem;

		.input-icon-wrapper {
			width: 100%;
		}

		input {
			border: none !important;
			margin-bottom: 0;

			&:focus-within,
			&:active {
				border: none !important;
			}
		}

		&:focus-within {
			border-radius: 4px;
			border: 1px solid @primary1 !important;
		}

		.password-eye {
			cursor: pointer;
			margin: 0.25rem 0.5rem 0 0.5rem;
			width: 25px;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	input {
		font-family: @font-family;
		width: 100%;
		height: 2.5rem;
		font-size: 1rem;
		margin-bottom: 1.5rem;
		font-weight: lighter;
		background-color: @white;
		border-radius: 4px;
		border: 1px solid @greyscale3 !important;
		box-sizing: border-box;
		box-shadow: none;
		line-height: 1;

		&.error {
			border: 1px solid @negative1 !important;
			color: @black!important;
		}

		&:focus,
		&:active {
			border: 1px solid @primary1 !important;
		}

		&:disabled {
			background-color: @gray-30;
		}
	}
}

.omgevingWarning {
	position: absolute;
	top: 0;
	right: 0;
	width: 8rem;
	height: 8rem;
	text-align: center;
	overflow: hidden;
	z-index: 1;

	a {
		color: @white!important;
		border-radius: @border-radius;
		padding: @spacing / 2;
		display: block;
		line-height: 1.2rem;
		font-size: 0.7rem;
		font-weight: @medium;
		text-transform: uppercase;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		position: absolute;
		top: 1.7rem;
		left: 0rem;
		width: 10rem;
		font-weight: 700;
	}
}

.testOmgevingWarning {
	.omgevingWarning;
	a {
		background: @warning1;
	}
}

.acceptatieOmgevingWarning {
	.omgevingWarning;
	a {
		background: @tertiary21;
	}
}

.normalskip {
	margin-bottom: 1.5rem !important;
}

hr.normalskip {
	margin-bottom: 1.5rem;
}

.login--secondary--link {
	font-size: 1rem;
	cursor: pointer;
	color: @primary1;
	font-weight: 700;
	
	&:hover {
		color: @primary2;
	}
}

ul.login--secondary {
	margin: 1.25rem 0 0 0;
	padding: 0;
	list-style: none;

	li {
		padding: 0;
		margin: 0.75rem 0;
		list-style: none;
		display: block;
		text-decoration: none;

		a {
			.login--secondary--link;
		}

		&.vergeten {
			margin: 1.5rem 0 1.5rem 0;
		}
	}
}
	
.login--header {
	padding: 0.5rem @spacing 0;
	font-weight: normal;
}

.login--subtitle {
	top: -10px;
	position: relative;
	color: white;
	text-align: center;
	font-size: 25px;
	opacity: 0.8;
	font-family: @font-family;
	font-weight: 200;
	letter-spacing: 4px;
	line-height: 5px;
}

.login--info {
	margin-bottom: 1.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
	color: @font1;

	p,
	h2 {
		margin: 0;
		font-size: 1rem;
		font-weight: 400;
	}

	strong {
		display: block;
	}

	@media @breakpoint-media-one {
		&.right {
			display: block;
		}
	}

	&-noskip {
		&:extend(.login--info all);
		margin-bottom: 0;
	}
}

.login--footer {
	display: flex;
	justify-content: space-evenly;

	margin-top: 1.5rem;

	a:nth-child(2) {
		margin-left: 0.75rem;
	}
}

.login--accountlist {
	margin-bottom: 1.5rem;

	span {
		display: block;
		text-align: left;
	}

	.volledigeNaam {
		font-size: 1.2rem;
	}
}

.login--logo {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 1.5rem;

	img {
		width: 222px;
		height: 42px;
	}
}

.login--ticker {
	display: flex;
	justify-content: space-evenly;

	.ticker-element {
		height: 40px;
	}
}

.login--bottom-separator {
	margin-bottom: 1.5rem;
	padding: 1.5rem 0;
	border-bottom: 1px solid @greyscale3;
}

.not-clickable-on-mobile {
	@media @breakpoint-media-one-max {
		pointer-events: none;
	}
}

.login--messages ul {
	padding: 0;
	margin: 0;
	margin-bottom: 1.5rem;
	font-size: 1rem;
	color: @primary1;

	li {
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 0.875rem;
		word-break: break-word;

		&:not(:last-child) {
			margin-bottom: 0.75rem;
		}
	}
}

.login--insecure {
	font-size: 0.85rem;
	color: #e04343;
	margin-top: 1.5rem;
}

.login--title {
	padding: 0;
	margin: 0;
	padding-bottom: 0.5rem;
	color: @font1;
	font-size: 1.5rem;
	margin-bottom: 0.75rem;
	font-weight: 500;

	h2 {
		margin: 0;
		font-size: 1.4rem;
		margin-bottom: 1.25rem;
	}

	p {
		margin: 0;
		font-size: 0.867rem;
	}
}

.login--container {
	width: auto;
	padding: 1.5rem;

	@media @breakpoint-media-one {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50rem;
		padding: 0.75rem;
	}

	h2 {
		font-size: 2rem;
		margin-bottom: 2.5rem;
		font-weight: 700;

		@media @breakpoint-media-one {
			margin-bottom: 2rem;
		}
	}
}

.login--keuzes {
	font-family: @font-family;
	.mixin-display-flex();
	.mixin-align-items(center);
	.mixin-justify-content(center);
	.mixin-flex-wrap(wrap);

	div {
		width: 100%;

		&:hover {
			opacity: 0.9;
		}

		@media @breakpoint-media-one {
			width: 25%;
		}

		p {
			color: @font1;
			font-size: 0.875rem;
			font-weight: 400;
		}
	}
}

// https://www.w3schools.com/howto/howto_css_placeholder.asp
::placeholder {
	color: @color-neutral-40!important;
}
::-ms-input-placeholder {
	color: @color-neutral-40!important;
}

.display_none {
	display: none;
}

.break_word {
	word-break: break-word;
}

.no_text_transform {
	text-transform: none;
}

.token_margin {
	margin-top: 1.5rem;
}