// FortyTwo fonts via Icomoon iconfont

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "@{icomoon-font-family}" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-compare {
	&:before {
		content: @icon-compare;
	}
}
.icon-align-center {
	&:before {
		content: @icon-align-center;
	}
}
.icon-align-left {
	&:before {
		content: @icon-align-left;
	}
}
.icon-align-right {
	&:before {
		content: @icon-align-right;
	}
}
.icon-mute {
	&:before {
		content: @icon-mute;
	}
}
.icon-mute-off {
	&:before {
		content: @icon-mute-off;
	}
}
.icon-ban {
	&:before {
		content: @icon-ban;
	}
}
.icon-bar-chart {
	&:before {
		content: @icon-bar-chart;
	}
}
.icon-mute-notifications {
	&:before {
		content: @icon-mute-notifications;
	}
}
.icon-notifications {
	&:before {
		content: @icon-notifications;
	}
}
.icon-font-bold {
	&:before {
		content: @icon-font-bold;
	}
}
.icon-calendar-check {
	&:before {
		content: @icon-calendar-check;
	}
}
.icon-calendar-cross {
	&:before {
		content: @icon-calendar-cross;
	}
}
.icon-calendar-minus {
	&:before {
		content: @icon-calendar-minus;
	}
}
.icon-calendar-date {
	&:before {
		content: @icon-calendar-date;
	}
}
.icon-calendar-plus {
	&:before {
		content: @icon-calendar-plus;
	}
}
.icon-calendar-square {
	&:before {
		content: @icon-calendar-square;
	}
}
.icon-checkmark {
	&:before {
		content: @icon-checkmark;
	}
}
.icon-schedule-date {
	&:before {
		content: @icon-schedule-date;
	}
}
.icon-calendar-empty {
	&:before {
		content: @icon-calendar-empty;
	}
}
.icon-calendar-squares {
	&:before {
		content: @icon-calendar-squares;
	}
}
.icon-questionmark {
	&:before {
		content: @icon-questionmark;
	}
}
.icon-duplicate {
	&:before {
		content: @icon-duplicate;
	}
}
.icon-user-add {
	&:before {
		content: @icon-user-add;
	}
}
.icon-grip-horizontal {
	&:before {
		content: @icon-grip-horizontal;
	}
}
.icon-star {
	&:before {
		content: @icon-star;
	}
}
.icon-search {
	&:before {
		content: @icon-search;
	}
}
.icon-star-alt {
	&:before {
		content: @icon-star-alt;
	}
}
.icon-print {
	&:before {
		content: @icon-print;
	}
}
.icon-lock {
	&:before {
		content: @icon-lock;
	}
}
.icon-checkmark-alt {
	&:before {
		content: @icon-checkmark-alt;
	}
}
.icon-user-clock {
	&:before {
		content: @icon-user-clock;
	}
}
.icon-grip-diagonal {
	&:before {
		content: @icon-grip-diagonal;
	}
}
.icon-wifi {
	&:before {
		content: @icon-wifi;
	}
}
.icon-spinner {
	&:before {
		content: @icon-spinner;
	}
}
.icon-camera {
	&:before {
		content: @icon-camera;
	}
}
.icon-add {
	&:before {
		content: @icon-add;
	}
}
.icon-upload {
	&:before {
		content: @icon-upload;
	}
}
.icon-filter {
	&:before {
		content: @icon-filter;
	}
}
.icon-task-list {
	&:before {
		content: @icon-task-list;
	}
}
.icon-number-list {
	&:before {
		content: @icon-number-list;
	}
}
.icon-compress {
	&:before {
		content: @icon-compress;
	}
}
.icon-show {
	&:before {
		content: @icon-show;
	}
}
.icon-link-right {
	&:before {
		content: @icon-link-right;
	}
}
.icon-unlock {
	&:before {
		content: @icon-unlock;
	}
}
.icon-play {
	&:before {
		content: @icon-play;
	}
}
.icon-sliders {
	&:before {
		content: @icon-sliders;
	}
}
.icon-add-alt {
	&:before {
		content: @icon-add-alt;
	}
}
.icon-comment {
	&:before {
		content: @icon-comment;
	}
}
.icon-remove {
	&:before {
		content: @icon-remove;
	}
}
.icon-bullet-list {
	&:before {
		content: @icon-bullet-list;
	}
}
.icon-play-alt {
	&:before {
		content: @icon-play-alt;
	}
}
.icon-school {
	&:before {
		content: @icon-school;
	}
}
.icon-remove-alt {
	&:before {
		content: @icon-remove-alt;
	}
}
.icon-hide {
	&:before {
		content: @icon-hide;
	}
}
.icon-users {
	&:before {
		content: @icon-users;
	}
}
.icon-user {
	&:before {
		content: @icon-user;
	}
}
.icon-sign-out {
	&:before {
		content: @icon-sign-out;
	}
}
.icon-unlink {
	&:before {
		content: @icon-unlink;
	}
}
.icon-user-delete {
	&:before {
		content: @icon-user-delete;
	}
}
.icon-exclamation-expand {
	&:before {
		content: @icon-exclamation-expand;
	}
}
.icon-sync {
	&:before {
		content: @icon-sync;
	}
}
.icon-new-post {
	&:before {
		content: @icon-new-post;
	}
}
.icon-underline {
	&:before {
		content: @icon-underline;
	}
}
.icon-sign-in {
	&:before {
		content: @icon-sign-in;
	}
}
.icon-comment-question {
	&:before {
		content: @icon-comment-question;
	}
}
.icon-support {
	&:before {
		content: @icon-support;
	}
}
.icon-trashcan {
	&:before {
		content: @icon-trashcan;
	}
}
.icon-data-table {
	&:before {
		content: @icon-data-table;
	}
}
.icon-link-left {
	&:before {
		content: @icon-link-left;
	}
}
.icon-stop {
	&:before {
		content: @icon-stop;
	}
}
.icon-pause {
	&:before {
		content: @icon-pause;
	}
}
.icon-download {
	&:before {
		content: @icon-download;
	}
}
.icon-image {
	&:before {
		content: @icon-image;
	}
}
.icon-exclamation-alt {
	&:before {
		content: @icon-exclamation-alt;
	}
}
.icon-comment-alt {
	&:before {
		content: @icon-comment-alt;
	}
}
.icon-clock {
	&:before {
		content: @icon-clock;
	}
}
.icon-comment-cross {
	&:before {
		content: @icon-comment-cross;
	}
}
.icon-info {
	&:before {
		content: @icon-info;
	}
}
.icon-info-alt {
	&:before {
		content: @icon-info-alt;
	}
}
.icon-share {
	&:before {
		content: @icon-share;
	}
}
.icon-settings {
	&:before {
		content: @icon-settings;
	}
}
.icon-comment-plus {
	&:before {
		content: @icon-comment-plus;
	}
}
.icon-reload {
	&:before {
		content: @icon-reload;
	}
}
.icon-cross {
	&:before {
		content: @icon-cross;
	}
}
.icon-exclamation-triangle {
	&:before {
		content: @icon-exclamation-triangle;
	}
}
.icon-ondersteuningsbehoefte {
	&:before {
		content: @icon-ondersteuningsbehoefte;
	}
}
.icon-group {
	&:before {
		content: @icon-group;
	}
}
.icon-italic {
	&:before {
		content: @icon-italic;
	}
}
.icon-attachment {
	&:before {
		content: @icon-attachment;
	}
}
.icon-paste {
	&:before {
		content: @icon-paste;
	}
}
.icon-edit {
	&:before {
		content: @icon-edit;
	}
}
.icon-redo {
	&:before {
		content: @icon-redo;
	}
}
.icon-undo {
	&:before {
		content: @icon-undo;
	}
}
.icon-shield {
	&:before {
		content: @icon-shield;
	}
}
.icon-cross-alt {
	&:before {
		content: @icon-cross-alt;
	}
}
.icon-send {
	&:before {
		content: @icon-send;
	}
}
.icon-ink-pencil {
	&:before {
		content: @icon-ink-pencil;
	}
}
.icon-wallet {
	&:before {
		content: @icon-wallet;
	}
}
.icon-a-exclamation {
	&:before {
		content: @icon-a-exclamation;
	}
}
.icon-bank {
	&:before {
		content: @icon-bank;
	}
}
.icon-book-open {
	&:before {
		content: @icon-book-open;
	}
}
.icon-brain {
	&:before {
		content: @icon-brain;
	}
}
.icon-bullhorn {
	&:before {
		content: @icon-bullhorn;
	}
}
.icon-coffee-alt {
	&:before {
		content: @icon-coffee-alt;
	}
}
.icon-coffee {
	&:before {
		content: @icon-coffee;
	}
}
.icon-compass {
	&:before {
		content: @icon-compass;
	}
}
.icon-cross-colon {
	&:before {
		content: @icon-cross-colon;
	}
}
.icon-cube {
	&:before {
		content: @icon-cube;
	}
}
.icon-dev {
	&:before {
		content: @icon-dev;
	}
}
.icon-double-checkmark {
	&:before {
		content: @icon-double-checkmark;
	}
}
.icon-ear {
	&:before {
		content: @icon-ear;
	}
}
.icon-grammar {
	&:before {
		content: @icon-grammar;
	}
}
.icon-hand-up {
	&:before {
		content: @icon-hand-up;
	}
}
.icon-hint-down {
	&:before {
		content: @icon-hint-down;
	}
}
.icon-key {
	&:before {
		content: @icon-key;
	}
}
.icon-kid {
	&:before {
		content: @icon-kid;
	}
}
.icon-lightbulb {
	&:before {
		content: @icon-lightbulb;
	}
}
.icon-lighthouse {
	&:before {
		content: @icon-lighthouse;
	}
}
.icon-like-active {
	&:before {
		content: @icon-like-active;
	}
}
.icon-like {
	&:before {
		content: @icon-like;
	}
}
.icon-line-jump {
	&:before {
		content: @icon-line-jump;
	}
}
.icon-marking {
	&:before {
		content: @icon-marking;
	}
}
.icon-materials {
	&:before {
		content: @icon-materials;
	}
}
.icon-maths {
	&:before {
		content: @icon-maths;
	}
}
.icon-money {
	&:before {
		content: @icon-money;
	}
}
.icon-muscle {
	&:before {
		content: @icon-muscle;
	}
}
.icon-percentage {
	&:before {
		content: @icon-percentage;
	}
}
.icon-plus-minus {
	&:before {
		content: @icon-plus-minus;
	}
}
.icon-poll-active {
	&:before {
		content: @icon-poll-active;
	}
}
.icon-poll {
	&:before {
		content: @icon-poll;
	}
}
.icon-road-sign {
	&:before {
		content: @icon-road-sign;
	}
}
.icon-ruler {
	&:before {
		content: @icon-ruler;
	}
}
.icon-slides {
	&:before {
		content: @icon-slides;
	}
}
.icon-smiley-line {
	&:before {
		content: @icon-smiley-line;
	}
}
.icon-smiley {
	&:before {
		content: @icon-smiley;
	}
}
.icon-spelling {
	&:before {
		content: @icon-spelling;
	}
}
.icon-teacher {
	&:before {
		content: @icon-teacher;
	}
}
.icon-airplane {
	&:before {
		content: @icon-airplane;
	}
}
.icon-apple {
	&:before {
		content: @icon-apple;
	}
}
.icon-banana {
	&:before {
		content: @icon-banana;
	}
}
.icon-bat {
	&:before {
		content: @icon-bat;
	}
}
.icon-bread {
	&:before {
		content: @icon-bread;
	}
}
.icon-bunny {
	&:before {
		content: @icon-bunny;
	}
}
.icon-car {
	&:before {
		content: @icon-car;
	}
}
.icon-cat {
	&:before {
		content: @icon-cat;
	}
}
.icon-cloud {
	&:before {
		content: @icon-cloud;
	}
}
.icon-dog {
	&:before {
		content: @icon-dog;
	}
}
.icon-duck {
	&:before {
		content: @icon-duck;
	}
}
.icon-fish {
	&:before {
		content: @icon-fish;
	}
}
.icon-flower {
	&:before {
		content: @icon-flower;
	}
}
.icon-football {
	&:before {
		content: @icon-football;
	}
}
.icon-ghost {
	&:before {
		content: @icon-ghost;
	}
}
.icon-globe {
	&:before {
		content: @icon-globe;
	}
}
.icon-heart {
	&:before {
		content: @icon-heart;
	}
}
.icon-horse {
	&:before {
		content: @icon-horse;
	}
}
.icon-leaf {
	&:before {
		content: @icon-leaf;
	}
}
.icon-milkshake {
	&:before {
		content: @icon-milkshake;
	}
}
.icon-moon {
	&:before {
		content: @icon-moon;
	}
}
.icon-mushroom {
	&:before {
		content: @icon-mushroom;
	}
}
.icon-music {
	&:before {
		content: @icon-music;
	}
}
.icon-paw {
	&:before {
		content: @icon-paw;
	}
}
.icon-petunia {
	&:before {
		content: @icon-petunia;
	}
}
.icon-pine-tree {
	&:before {
		content: @icon-pine-tree;
	}
}
.icon-pizza {
	&:before {
		content: @icon-pizza;
	}
}
.icon-sailboat {
	&:before {
		content: @icon-sailboat;
	}
}
.icon-snowflake {
	&:before {
		content: @icon-snowflake;
	}
}
.icon-strawberry {
	&:before {
		content: @icon-strawberry;
	}
}
.icon-sun {
	&:before {
		content: @icon-sun;
	}
}
.icon-toucan {
	&:before {
		content: @icon-toucan;
	}
}
.icon-angle-down {
	&:before {
		content: @icon-angle-down;
	}
}
.icon-angle-left {
	&:before {
		content: @icon-angle-left;
	}
}
.icon-angle-right {
	&:before {
		content: @icon-angle-right;
	}
}
.icon-angle-up {
	&:before {
		content: @icon-angle-up;
	}
}
.icon-arrow-down {
	&:before {
		content: @icon-arrow-down;
	}
}
.icon-arrow-left {
	&:before {
		content: @icon-arrow-left;
	}
}
.icon-arrow-right {
	&:before {
		content: @icon-arrow-right;
	}
}
.icon-arrow-up {
	&:before {
		content: @icon-arrow-up;
	}
}
.icon-caret-down {
	&:before {
		content: @icon-caret-down;
	}
}
.icon-caret-left {
	&:before {
		content: @icon-caret-left;
	}
}
.icon-caret-right {
	&:before {
		content: @icon-caret-right;
	}
}
.icon-caret-up {
	&:before {
		content: @icon-caret-up;
	}
}
.icon-dots-horizontal {
	&:before {
		content: @icon-dots-horizontal;
	}
}
.icon-dots-vertical {
	&:before {
		content: @icon-dots-vertical;
	}
}
.icon-drag {
	&:before {
		content: @icon-drag;
	}
}
.icon-first-page {
	&:before {
		content: @icon-first-page;
	}
}
.icon-hamburger {
	&:before {
		content: @icon-hamburger;
	}
}
.icon-home {
	&:before {
		content: @icon-home;
	}
}
.icon-last-page {
	&:before {
		content: @icon-last-page;
	}
}
.icon-launch {
	&:before {
		content: @icon-launch;
	}
}
.icon-launch-after {
	&:after {
		content: @icon-launch;
	}
}
.icon-shopping-cart {
	&:before {
		content: @icon-shopping-cart;
	}
}
.icon-allergy {
	&:before {
		content: @icon-allergy;
	}
}
.icon-birthbay-cake {
	&:before {
		content: @icon-birthbay-cake;
	}
}
.icon-cardio {
	&:before {
		content: @icon-cardio;
	}
}
.icon-family {
	&:before {
		content: @icon-family;
	}
}
.icon-flag {
	&:before {
		content: @icon-flag;
	}
}
.icon-gender {
	&:before {
		content: @icon-gender;
	}
}
.icon-incognito {
	&:before {
		content: @icon-incognito;
	}
}
.icon-location-map {
	&:before {
		content: @icon-location-map;
	}
}
.icon-location {
	&:before {
		content: @icon-location;
	}
}
.icon-mail-read {
	&:before {
		content: @icon-mail-read;
	}
}
.icon-mail-unread {
	&:before {
		content: @icon-mail-unread;
	}
}
.icon-phone-warning {
	&:before {
		content: @icon-phone-warning;
	}
}
.icon-phone {
	&:before {
		content: @icon-phone;
	}
}
.icon-pills {
	&:before {
		content: @icon-pills;
	}
}
.icon-domain {
	&:before {
		content: @icon-domain;
	}
}
.icon-goal {
	&:before {
		content: @icon-goal;
	}
}
.icon-method {
	&:before {
		content: @icon-method;
	}
}
.icon-microgoal {
	&:before {
		content: @icon-microgoal;
	}
}
.icon-test-section {
	&:before {
		content: @icon-test-section;
	}
}
.icon-test {
	&:before {
		content: @icon-test;
	}
}
.icon-gynzy {
	&:before {
		content: @icon-gynzy;
	}
}
.icon-Kindbegrip {
	&:before {
		content: @icon-Kindbegrip;
	}
}
.icon-Leerlijnen {
	&:before {
		content: @icon-Leerlijnen;
	}
}
.icon-Ouderportaal {
	&:before {
		content: @icon-Ouderportaal;
	}
}
.icon-parnasSys {
	&:before {
		content: @icon-parnasSys;
	}
}
.icon-parro {
	&:before {
		content: @icon-parro;
	}
}
.icon-parro2 {
	&:before {
		content: @icon-parro2;
	}
}
.icon-Schoolkassa {
	&:before {
		content: @icon-Schoolkassa;
	}
}
.icon-Schoolkwaliteit {
	&:before {
		content: @icon-Schoolkwaliteit;
	}
}
.icon-Ultimview {
	&:before {
		content: @icon-Ultimview;
	}
}
.icon-vimeo {
	&:before {
		content: @icon-vimeo;
	}
}
.icon-youtube {
	&:before {
		content: @icon-youtube;
	}
}
.icon-audio-file {
	&:before {
		content: @icon-audio-file;
	}
}
.icon-file-empty {
	&:before {
		content: @icon-file-empty;
	}
}
.icon-folder {
	&:before {
		content: @icon-folder;
	}
}
.icon-image-alt {
	&:before {
		content: @icon-image-alt;
	}
}
.icon-image-file {
	&:before {
		content: @icon-image-file;
	}
}
.icon-pdf-alt {
	&:before {
		content: @icon-pdf-alt;
	}
}
.icon-pdf {
	&:before {
		content: @icon-pdf;
	}
}
.icon-presentation-alt {
	&:before {
		content: @icon-presentation-alt;
	}
}
.icon-presentation {
	&:before {
		content: @icon-presentation;
	}
}
.icon-save {
	&:before {
		content: @icon-save;
	}
}
.icon-spreadsheets-alt {
	&:before {
		content: @icon-spreadsheets-alt;
	}
}
.icon-spreadsheets {
	&:before {
		content: @icon-spreadsheets;
	}
}
.icon-text-file {
	&:before {
		content: @icon-text-file;
	}
}
.icon-video-alt {
	&:before {
		content: @icon-video-alt;
	}
}
.icon-video-file {
	&:before {
		content: @icon-video-file;
	}
}
.icon-checkbox-minus {
	&:before {
		content: @icon-checkbox-minus;
	}
}
.icon-checkbox-off {
	&:before {
		content: @icon-checkbox-off;
	}
}
.icon-checkbox-on {
	&:before {
		content: @icon-checkbox-on;
	}
}
.icon-radio-checked {
	&:before {
		content: @icon-radio-checked;
	}
}
.icon-radio-unchecked {
	&:before {
		content: @icon-radio-unchecked;
	}
}
.icon-toggle-off {
	&:before {
		content: @icon-toggle-off;
	}
}
.icon-toggle-on {
	&:before {
		content: @icon-toggle-on;
	}
}
