// =========== Mixins

.mixin-animation(@name, @duration: 300ms, @delay: 0, @ease: ease) {
	-webkit-animation: @name @duration @delay @ease;
	-moz-animation: @name @duration @delay @ease;
	-ms-animation: @name @duration @delay @ease;
}
.mixin-backface-visibility(@backface) {
	-webkit-backface-visibility: @backface;
	-moz-backface-visibility: @backface;
	-ms-backface-visibility: @backface;
	backface-visibility: @backface;
}

.mixin-background-clip(@clip) {
	-webkit-background-clip: @clip;
	-moz-background-clip: @clip;
	background-clip: @clip;
}

.mixin-border-radius(@radius) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	-ms-border-radius: @radius;
	border-radius: @radius;
}

.mixin-box-shadow(@shadow) {
	-webkit-box-shadow: @shadow;
	-moz-box-shadow: @shadow;
	box-shadow: @shadow;
}

.mixin-box-sizing(@box) {
	-webkit-box-sizing: @box;
	-moz-box-sizing: @box;
	-ms-box-sizing: @box;
	box-sizing: @box;
}

/*.mixin-breakpoint(@point) {
    @if @point == one {
        @media only screen and (@breakpoint-property: @breakpoint-one) { @content; }
    }
    @else if @point == two {
        @media only screen and (@breakpoint-property: @breakpoint-two) { @content; }
    }
    @else if @point == three {
        @media only screen and (@breakpoint-property: @breakpoint-three)  { @content; }
    }
    @else if @point == four {
        @media only screen and (@breakpoint-property: @breakpoint-four)  { @content; }
    }
}*/

// .mixin-breakpoint(@point) when (@point = one) {
//     @media only screen and (min-width: @breakpoint-one) { @content; }
// }
// .mixin-breakpoint(@point) when (@point = two) {
//     @media only screen and (min-width: @breakpoint-two) { @content; }
// }
// .mixin-breakpoint(@point) when (@point = three) {
//     @media only screen and (min-width: @breakpoint-three) { @content; }
// }

.mixin-filter(@filter) {
	-webkit-filter: @filter;
	-moz-filter: @filter;
	-o-filter: @filter;
	filter: @filter;
}

.mixin-perspective(@perspective) {
	-webkit-perspective: @perspective;
	-moz-perspective: @perspective;
	perspective: @perspective;
}

.mixin-transform(@transform) {
	-webkit-transform: @transform;
	-moz-transform: @transform;
	-ms-transform: @transform;
	-o-transform: @transform;
	transform: @transform;
}

.mixin-transition(@transition) {
	-webkit-transition: @transition;
	-moz-transition: @transition;
	-o-transition: @transition;
	transition: @transition;
}

.mixin-columns(@count, @gap) {
	-webkit-column-count: @count;
	-webkit-column-gap: @gap;
	-moz-column-count: @count;
	-moz-column-gap: @gap;
	-o-column-count: @count;
	-o-column-gap: @gap;
	column-count: @count;
	column-gap: @gap;
}

.mixin-columns-rule(@size, @style, @color) {
	-webkit-column-rule-width: @size;
	-webkit-column-rule-style: @style;
	-webkit-column-rule-color: @color;
	-moz-column-rule-width: @size;
	-moz-column-rule-style: @style;
	-moz-column-rule-color: @color;
	-o-column-rule-width: @size;
	-o-column-rule-style: @style;
	-o-column-rule-color: @color;
	column-rule-width: @size;
	column-rule-style: @style;
	column-rule-color: @color;
}

.mixin-gradient(@side, @start, @end) {
	background-color: @end;
	background-image: -webkit-linear-gradient(@side, @start, @end);
	background-image: -moz-linear-gradient(@side, @start, @end);
	background-image: -ms-linear-gradient(@side, @start, @end);
	background-image: -o-linear-gradient(@side, @start, @end);
	background-image: linear-gradient(@side, @start, @end);
}

.mixin-gradient-ie(@start, @end) {
	*zoom: 1;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{@start}', endColorstr='#{@end}');
	-ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{@start}', endColorstr='#{@end}');
}

// ajax loader animations

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-o-keyframes spin {
	from {
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

// mixins

@radius: 2px;

.rounded-corners (@radius: 0px) {
	.rounded-corners-top (@radius);
	.rounded-corners-bottom (@radius);
}
.rounded-corners-top (@radius: 0px) {
	.rounded-corners-top-left(@radius);
	.rounded-corners-top-right(@radius);
}
.rounded-corners-bottom (@radius: 0px) {
	.rounded-corners-bottom-left(@radius);
	.rounded-corners-bottom-right(@radius);
}
.rounded-corners-left (@radius: 0px) {
	.rounded-corners-top-left(@radius);
	.rounded-corners-bottom-left(@radius);
}
.rounded-corners-right (@radius: 0px) {
	.rounded-corners-top-right(@radius);
	.rounded-corners-bottom-right(@radius);
}
.rounded-corners-top-left (@radius: 0px) {
	border-top-left-radius: @radius;
}
.rounded-corners-top-right(@radius: 0px) {
	border-top-right-radius: @radius;
}
.rounded-corners-bottom-left (@radius: 0px) {
	border-bottom-left-radius: @radius;
}
.rounded-corners-bottom-right (@radius: 0px) {
	border-bottom-right-radius: @radius;
}
