table.table {
	border: 0;
	border-collapse: collapse;
	border-spacing: 0 5px;
	width: 100%;
	text-align: left;

	thead tr th {
		border-bottom: 1px solid @greyscale2;
		border-collapse: separate;
		border-spacing: 5px 5px;
		padding: 0.75rem !important;
		color: @greyscale2;
	}

	tbody {
		td {
			border-collapse: separate;
			border-spacing: 5px 5px;
			padding: 0.75rem;
		}

		tr:nth-child(odd) {
			background: @greyscale6;
		}

		tr:hover {
			background: @greyscale5;
		}
	}
}
